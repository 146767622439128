import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "signatures",
  "category": "Getting Started",
  "sort": 2,
  "title": "SDKs & codegen",
  "subtitle": "GraphQL is ideal for codegenerated clients, but we also offer SDKs for select platforms."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.graphql-code-generator.com/"
      }}>{`GraphQL Code Generator project`}</a>{` supports generating clients for `}<inlineCode parentName="p">{`Node.js`}</inlineCode>{`, `}<inlineCode parentName="p">{`C#`}</inlineCode>{` and `}<inlineCode parentName="p">{`Java`}</inlineCode>{`.`}</p>
    <h2>{`Schema`}</h2>
    <p>{`The GraphQL schema for codegen can be fetched by making a `}<inlineCode parentName="p">{`GET`}</inlineCode>{` request to the GraphQL Endpoint `}<inlineCode parentName="p">{`https://signatures-api.criipto.com/v1/graphql`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`curl https://signatures-api.criipto.com/v1/graphql
`}</code></pre>
    <h2>{`Node.js`}</h2>
    <p>{`This example uses `}<a parentName="p" {...{
        "href": "https://www.graphql-code-generator.com/"
      }}>{`GraphQL Code Generator`}</a>{` and is available at `}<a parentName="p" {...{
        "href": "https://github.com/criipto/samples/tree/master/Signing/nodejs"
      }}>{`GitHub`}</a></p>
    <h2>{`.NET (dotnet)`}</h2>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://github.com/criipto/criipto-signatures-dotnet"
      }}>{`C#/.NET SDK`}</a>{` is provided and maintained by Criipto.
Follow along in `}<a parentName="p" {...{
        "href": "/signatures/integrations/csharp/"
      }}>{`tutorial`}</a>{` or see all our `}<a parentName="p" {...{
        "href": "/signatures/graphql/examples/"
      }}>{`C#/.NET examples`}</a>{`.`}</p>
    <h2>{`PHP`}</h2>
    <p>{`This example uses `}<a parentName="p" {...{
        "href": "https://github.com/spawnia/sailor"
      }}>{`Sailor`}</a>{` and is avialable at `}<a parentName="p" {...{
        "href": "https://github.com/criipto/samples/tree/master/Signing/php"
      }}>{`GitHub`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      